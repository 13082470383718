import {createContext, Dispatch, SetStateAction, useEffect, useState} from 'react'
import qs from 'qs'
import {ID, QueryResponseContextProps, QueryState} from './models'
import {ASPECT_RATIO_16_9, ASPECT_RATIO_1_1, SIZE_KB250, SIZE_MB1} from './consts'

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'items_per_page'], skipNulls: true})
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: true})
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  return data.length > 0 && data.length === selected.length
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & {id?: ID}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

function groupingOnSelectAllV2<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  data?: Array<T & {id: any}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }

  setSelected(data.filter((item) => item.id).map((item) => item.id))
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

function groupMenusByLevelAndOrder(menus: any[]) {
  function groupByLevel(menuList: any = [], currentLevel = 1, parentId = '') {
    const groupedMenus: any[] = []
    for (const menu of menuList) {
      if (menu.level === currentLevel && menu.parent_menu_id === parentId) {
        const subMenu = groupByLevel(menuList, currentLevel + 1, menu.id)
        if (subMenu.length > 0) {
          menu.sub_menu = subMenu
        } else {
          const hasAccess = menu?.has_access
          if (
            !hasAccess.is_create &&
            !hasAccess.is_update &&
            !hasAccess.is_delete &&
            !hasAccess.is_read
          ) {
            continue
          }
        }
        groupedMenus.push(menu)
      }
    }
    return groupedMenus
  }

  const sortedMenus = menus?.sort((a: any, b: any) => {
    if (a.level === b.level) {
      return a.order_number - b.order_number
    }
    return a.level - b.level
  })

  return groupByLevel(sortedMenus)
}

// for generate random string
function generateRandomString(length: number) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

/**
 * validate image size < 1mb, size > 250kb with ratio 16/9
 * @function validateImage1250_169
 */
function validateImage1250_169({file, onError}: {file: File; onError: (msg: string) => void}) {
  if (file.size > SIZE_MB1) {
    onError('Ukuran gambar tidak boleh lebih dari 1 MB.')
    return false
  } else if (file.size < SIZE_KB250) {
    onError('Ukuran gambar minimal 250 KB.')
    return false
  }

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      const width = img.width
      const height = img.height
      const ratio = width / height

      if (Math.abs(ratio - ASPECT_RATIO_16_9) < 0.01) {
        resolve(true)
      } else {
        onError('Gambar harus memiliki rasio 16:9.')
        resolve(false)
      }
    }
    img.onerror = function () {
      onError('Gagal memuat gambar.')
      reject(false)
    }
    img.src = URL.createObjectURL(file)
  })
}

/**
 * validate image size < 1mb, size > 250kb with ratio 1/1
 * @function validateImage1250_169
 */
function validateImage250250_11({file, onError}: {file: File; onError: (msg: string) => void}) {
  if (file.size > SIZE_MB1) {
    onError('Ukuran gambar tidak boleh lebih dari 1 MB.')
    return false
  } else if (file.size < SIZE_KB250) {
    onError('Ukuran gambar minimal 250 KB.')
    return false
  }

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      const width = img.width
      const height = img.height
      const ratio = width / height

      if (Math.abs(ratio - ASPECT_RATIO_1_1) < 0.01) {
        resolve(true)
      } else {
        onError('Gambar harus memiliki rasio 1:1.')
        resolve(false)
      }
    }
    img.onerror = function () {
      onError('Gagal memuat gambar.')
      reject(false)
    }
    img.src = URL.createObjectURL(file)
  })
}

/**
 * validate image size < 250kb, size > 250kb with ratio 1/1
 * @function validateImage1250_169
 */
function validateImage1250_11({file, onError}: {file: File; onError: (msg: string) => void}) {
  if (file.size > SIZE_MB1) {
    onError('Ukuran gambar tidak boleh lebih dari 1 MB.')
    return false
  } else if (file.size < SIZE_KB250) {
    onError('Ukuran gambar minimal 250 KB.')
    return false
  }

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      const width = img.width
      const height = img.height
      const ratio = width / height

      if (Math.abs(ratio - ASPECT_RATIO_1_1) < 0.01) {
        resolve(true)
      } else {
        onError('Gambar harus memiliki rasio 1:1.')
        resolve(false)
      }
    }
    img.onerror = function () {
      onError('Gagal memuat gambar.')
      reject(false)
    }
    img.src = URL.createObjectURL(file)
  })
}

export {
  createResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  groupingOnSelectAllV2,
  useDebounce,
  isNotEmpty,
  groupMenusByLevelAndOrder,
  generateRandomString,
  validateImage1250_169,
  validateImage1250_11,
  validateImage250250_11,
}
