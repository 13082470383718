const RESP = {
  SUCCESS: '00',
  MSG_SUCCESS: 'success',
  MSG_SUCCESS_CAPITALIZE: 'Success',
}

const API_MESSAGE = {
  TOKEN_NOT_FOUND: 'Header `token` not found',
  UNAUTHORIZED: 'Unauthorized token',
  REFRESH_UNAUTHORIZED: 'Unauthorized refresh token',
  USER_UNAUTHORIZED: 'Unauthorized user',
  USER_NOT_LOGGED_IN: 'Please login first',
}

const WORKFLOW = {
  APPROVAL: '543a2926-661d-451f-8eac-409769de69eb',
  STORE_REQUEST: '9e705074-c6b6-4792-91d9-a5d45e4c21f2',
}

const NETWORK_ERROR = 'Network error. Please try again later'

export {RESP, API_MESSAGE, WORKFLOW, NETWORK_ERROR}
