import {KTCard, KTIcon} from '@/_metronic/helpers'
import {DropdownSelect} from '@/_metronic/partials/content/dropdown-select/DropdownSelect'
import {SingleDatePicker} from '@/_metronic/partials/content/single-datepicker/SingleDatePicker'
import {uniqueId, values} from 'lodash'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {FormattedNumber, IntlProvider} from 'react-intl'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {DropdownOutlet} from './components/DropdownOutlet'
import {DropdownSalesType} from './components/DropdownSalesType'
import {DropdownRawMaterial} from './components/DropdownRawMaterial'
import {DropdownSupplier} from './components/DropdownSupplier'
import * as Yup from 'yup'
import {FieldArray, Form, Formik, FormikContext, FormikProvider, useFormik} from 'formik'
import moment from 'moment'
import {formatRupiah, removeDots} from '@/app/utils'
import {RowDetails} from './components/RowDetails'
import {RESP, WORKFLOW} from '@/_metronic/helpers/network/network-consts'
import {DetailResponseProvider, useDetailResponse} from '../core/DetailResponseProvider'
import clsx from 'clsx'
import {useAuth} from '../../auth'
import {useSwal} from '@/app/core/SwalProvider'
import {toast} from 'react-toastify'
import {useDropzone} from 'react-dropzone'
import {mediaUpload} from '@/app/core/media/_requests'
import {CvSection} from './components/CvSection'
import {InputGroup} from '@/_metronic/partials/content/input-group/InputGroup'
import {Toggle} from '@/_metronic/partials/content/toggle/Toggle'
import {format, resolve} from 'path'
import ReactQuill from 'react-quill'
import {createSubscription, updateSubscription} from '../core/_requests'
import {watch} from 'fs'
import {SubscriptionDetail} from '../core/_models'

const productModifierInitialValues = {
  outlet: null,
  name: '',
  images: '',
  body: '',
  benefit: '',
  price: 0,
  discount: 0,
  sell_price: 0,
  expired_days: 0,
  is_include_tax: false,
  is_include_service: false,
  is_active: false,
  details: [
    {
      product_variant_sku: '',
      sell_price: 0,
      qty: 0,
    },
  ],
}

const productModifierSchemes = Yup.object().shape({
  outlet: Yup.object().shape({
    label: Yup.string().required(),
    value: Yup.string().required(),
  }),
  name: Yup.string().required('Nama tidak boleh kosong'),
  images: Yup.string(),
  body: Yup.string().required('Body tidak boleh kosong'),
  benefit: Yup.string().required('Benefit tidak boleh kosong'),
  price: Yup.number().required('Price tidak boleh kosong'),
  discount: Yup.number().required('Discount tidak boleh kosong'),
  sell_price: Yup.number().required('Sell Price tidak boleh kosong'),
  expired_days: Yup.number().required('Expired Days tidak boleh kosong'),
  is_include_tax: Yup.boolean().required('Is Include Tax tidak boleh kosong'),
  is_include_service: Yup.boolean().required('Is Include Service tidak boleh kosong'),
  is_active: Yup.boolean().required('Is Active tidak boleh kosong'),
  details: Yup.array()
    .of(
      Yup.object().shape({
        product_variant_sku: Yup.object().shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
          sell_price: Yup.number().required(),
        }),
        sell_price: Yup.number().required('Sell price tidak boleh kosong'),
        qty: Yup.number().required('Qty tidak boleh kosong'),
      })
    )
    .required(),
})

const SubsscriptionDetail = () => {
  const location = useLocation()
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false)
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const [valueRange, setValueRange] = useState<any>({minimum: 0, maximum: 0})
  const [isMaxQtyValid, setIsMaxQtyValid] = useState<any>([])
  const [selectedFile, setSelectedFile] = useState<any>(null)

  const {id} = useParams()
  const {data, refetch, file, uploadLoading, setUploadLoading, setFileUrl} = useDetailResponse()
  const {currentUser} = useAuth()
  const {swal} = useSwal()

  const navigate = useNavigate()
  const statusRef = useRef<string>('draft')

  const [files, setFiles] = useState([])

  const formik = useFormik({
    validationSchema: productModifierSchemes,
    initialValues: productModifierInitialValues,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: async (values: any) => {
      try {
        const body = {
          outlet_id: values.outlet.value,
          name: values.name,
          images: values.image,
          body: values.body,
          benefit: values.benefit,
          price: values.price,
          discount: values.discount,
          sell_price: values.sell_price,
          expired_days: values.expired_days,
          is_include_tax: values.is_include_tax,
          is_include_service: values.is_include_service,
          is_active: values.is_active,
          details: values.details.map((item) => {
            return {
              guid: id ? item.guid : undefined,
              product_variant_sku: item.product_variant_sku.sku,
              qty: item.qty,
              action: id ? item.action : undefined,
            }
          }),
        }

        if (id) {
          const res = await updateSubscription(id, body)
          if (res?.response?.code === RESP.SUCCESS) {
            toast.success('Subscription berhasil diupdate')
            navigate('/sales-marketing/subscription/list')
          }

          return
        }

        body.details = body.details.filter((item) => item.action !== 'delete')
        const res = await createSubscription(body)
        if (res?.response?.code === RESP.SUCCESS) {
          toast.success('Subscription berhasil dibuat')
          navigate('/sales-marketing/subscription/list')
        }
      } catch (error) {
        console.error(error)
      }
    },
  })
  const {values, handleChange, errors, setFieldValue, isValid, handleSubmit, touched, setValues} =
    formik

  const hasOutlet = useMemo(
    () => Boolean(currentUser?.detail_data?.outlet?.guid && currentUser?.detail_data?.outlet?.name),
    [currentUser]
  )

  useEffect(() => {
    if (!isReadOnly && hasOutlet) {
      const outlet = currentUser?.detail_data?.outlet
      setFieldValue('outlet', {label: outlet?.name, value: outlet?.guid})
      // setOutletReadOnly(true)
    }
  }, [hasOutlet, isReadOnly])

  useEffect(() => {
    if (window?.location?.pathname?.includes('/detail')) {
      setIsReadOnly(true)
    }
  }, [window?.location])

  useEffect(() => {
    if (data) {
      const detailData: any = {
        outlet: {
          label: data.outlet?.name,
          value: data.outlet?.id,
        },
        name: data.name,
        images: data.images,
        body: data.body,
        benefit: data.benefit,
        price: data.price,
        discount: data.discount,
        sell_price: data.sell_price,
        expired_days: data.expired_days,
        is_include_tax: data.is_include_tax,
        is_include_service: data.is_include_service,
        is_active: data.is_active,
        details: data?.details?.map((item: SubscriptionDetail) => {
          return {
            guid: item.id,
            product_variant_sku: {
              label: item.detail_product?.name,
              value: item?.id,
              sell_price: item.sell_price,
              sku: item.product_variant_sku,
            },
            qty: item.qty,
            sell_price: 0,
            action: 'update',
          }
        }),
      }

      setValues(detailData)
    }
  }, [data])

  useEffect(() => {
    if (location?.pathname?.includes('/detail')) {
      setIsReadOnly(true)
    }
  }, [location])

  useEffect(() => {
    setFieldValue('sell_price', values.price - values.discount)
  }, [values.price, values.discount])

  return (
    <>
      <FormikProvider value={formik}>
        <div className='d-flex flex-column flex-lg-row'>
          <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
            <div className='card card-flush'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>Subscription</h2>
                </div>
              </div>
              <div className='card-body pt-0'>
                <div className='row mt-3'>
                  <div className='col-6'>
                    <DropdownOutlet
                      label='Outlet'
                      onValueChange={(newValue) => {
                        setFieldValue('outlet', newValue)
                        if (newValue?.value !== values?.outlet?.value) {
                          setFieldValue('details', productModifierInitialValues?.details)
                        }
                      }}
                      currentValue={values.outlet}
                      isDisabled={isReadOnly}
                    />
                  </div>
                  <div className='col-6'>
                    <DropdownSalesType
                      label='Sales Type'
                      onValueChange={(newValue) => {
                        setFieldValue('sales_type', newValue)
                      }}
                      currentValue={values.sales_type}
                    />
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-6'>
                    <div className='col-12'>
                      <InputGroup
                        label='Name'
                        isValid={!(Boolean(errors?.name) && Boolean(touched.name))}
                        errorMessage={errors?.name as string}
                        value={values.name}
                        onChange={formik.handleChange('name')}
                        onBlur={formik.handleBlur('name')}
                        disabled={isReadOnly}
                      />
                    </div>
                    <div className='col-12'>
                      <InputGroup
                        label='Benefit'
                        isValid={!(Boolean(errors?.benefit) && Boolean(touched.benefit))}
                        errorMessage={errors?.benefit as string}
                        value={values.benefit}
                        onChange={formik.handleChange('benefit')}
                        onBlur={formik.handleBlur('benefit')}
                        disabled={isReadOnly}
                      />
                    </div>
                    <div className='col-12'>
                      <InputGroup
                        label='Expired Days'
                        isValid={!(Boolean(errors?.expired_days) && Boolean(touched.expired_days))}
                        errorMessage={errors?.expired_days as string}
                        value={formatRupiah(values.expired_days)}
                        onChange={(e) => {
                          try {
                            const numberQty = parseInt(removeDots(e.target.value?.toString()))
                            setFieldValue(`expired_days`, isNaN(numberQty) ? '' : numberQty)
                          } catch (error) {
                            setFieldValue(`expired_days`, '')
                          }
                        }}
                        onBlur={formik.handleBlur('expired_days')}
                        disabled={isReadOnly}
                      />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='col-12'>
                      <label className='form-label required'>Body</label>
                      <ReactQuill
                        theme='snow'
                        value={values.body}
                        onChange={handleChange('body')}
                        // readOnly={isReadOnly}
                        className='subscription-quill'
                        // onBlur={handleBlur('description')}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-4'>
                    <InputGroup
                      label='Price'
                      isValid={!(Boolean(errors?.price) && Boolean(touched.price))}
                      errorMessage={errors?.name as string}
                      value={formatRupiah(values.price)}
                      onChange={(e) => {
                        try {
                          const numberQty = parseInt(removeDots(e.target.value?.toString()))
                          setFieldValue(`price`, isNaN(numberQty) ? '' : numberQty)
                        } catch (error) {
                          setFieldValue(`price`, '')
                        }
                      }}
                      onBlur={formik.handleBlur('price')}
                      disabled={isReadOnly}
                    />
                  </div>
                  <div className='col-4'>
                    <InputGroup
                      label='Discount'
                      isValid={!(Boolean(errors?.discount) && Boolean(touched.discount))}
                      errorMessage={errors?.discount as string}
                      value={formatRupiah(values.discount)}
                      onChange={(e) => {
                        try {
                          const numberQty = parseInt(removeDots(e.target.value?.toString()))
                          setFieldValue(`discount`, isNaN(numberQty) ? '' : numberQty)
                        } catch (error) {
                          setFieldValue(`discount`, '')
                        }
                      }}
                      onBlur={formik.handleBlur('discount')}
                      disabled={isReadOnly}
                    />
                  </div>
                  <div className='col-4'>
                    <InputGroup
                      className='bg-gray-300'
                      label='Sell Price'
                      isValid={!(Boolean(errors?.sell_price) && Boolean(touched.sell_price))}
                      errorMessage={errors?.sell_price as string}
                      value={formatRupiah(values.sell_price)}
                      onChange={(e) => {
                        try {
                          const numberQty = parseInt(removeDots(e.target.value?.toString()))
                          setFieldValue(`sell_price`, isNaN(numberQty) ? '' : numberQty)
                        } catch (error) {
                          setFieldValue(`sell_price`, '')
                        }
                      }}
                      onBlur={formik.handleBlur('sell_price')}
                      readOnly
                    />
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-4'>
                    <label className='form-label'>Is Include Tax</label>
                    <Toggle
                      id={`is_include_tax`}
                      checked={values.is_include_tax}
                      onChange={formik.handleChange('is_include_tax')}
                    />
                  </div>
                  <div className='col-4'>
                    <label className='form-label'>Is Include Service</label>
                    <Toggle
                      id={`is_include_service`}
                      checked={values.is_include_service}
                      onChange={formik.handleChange('is_include_service')}
                    />
                  </div>
                  <div className='col-4'>
                    <label className='form-label'>Is Active</label>
                    <Toggle
                      id={`is_active`}
                      checked={values.is_active}
                      onChange={formik.handleChange('is_active')}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='card card-flush mt-5'>
              <div className='card-header'>
                <div className='card-title'>
                  <h2>Detail</h2>
                </div>
              </div>
              <FieldArray
                name='details'
                render={(arrayHelpers) => (
                  <div className='card-body pt-0'>
                    <div className='table-responsive'>
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th>No</th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Action</th>
                            {!isReadOnly && <th></th>}
                          </tr>
                        </thead>
                        <tbody className='text-dark mb-1 fs-6'>
                          <>
                            <RowDetails isReadOnly={isReadOnly} arrayHelpers={arrayHelpers} />
                          </>
                        </tbody>
                      </table>
                    </div>
                    {!isReadOnly && (
                      <button
                        className='btn btn-primary mt-3'
                        type='button'
                        onClick={() => {
                          arrayHelpers.push({
                            product_variant_sku: '',
                            sell_price: 0,
                            qty: 0,
                            action: 'create',
                          })
                        }}
                      >
                        Add Detail
                      </button>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end gap-3 mt-5'>
          <Link to='/sales-marketing/subscription/list'>
            <a href='#' className='btn btn-light me-5'>
              {isReadOnly ? 'Back' : 'Cancel'}
            </a>
          </Link>
          {/* {isApproved && (
            <button
              className='btn btn-primary'
              disabled={!isValid}
              onClick={() => {
                // statusRef.current = 'waiting for approval'
                // handleSubmit()
                // handleProcessOrder()
              }}
            >
              Process Order
            </button>
          )} */}
          {/* {isInProcess && (
            <button
              className='btn btn-light-primary border border-primary'
              disabled={!isValid}
              onClick={() => {
                swal
                  ?.fire({
                    html: 'Apakah anda yakin ingin close PO ini?',
                    icon: 'warning',
                    buttonsStyling: false,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, batal',
                    showCancelButton: true,
                    customClass: {
                      confirmButton: 'btn fw-bold btn-danger',
                      cancelButton: 'btn fw-bold btn-active-light-primary',
                    },
                  })
                  .then(async (value) => {
                    if (value.isConfirmed) {
                      statusRef.current = 'finished'
                      handleSubmit()
                    }
                  })
              }}
            >
              Finish
            </button>
          )} */}
          {!isReadOnly && (
            <>
              <button
                className='btn btn-primary'
                disabled={
                  !isValid ||
                  isMaxQtyValid.find((item) => !item.valid) ||
                  values.minimum_quantity <
                    values.details.reduce((acc, val) => acc + val.min_quantity, 0)
                }
                onClick={() => {
                  statusRef.current = 'waiting for approval'
                  handleSubmit()
                }}
              >
                Save
              </button>
            </>
          )}
        </div>
      </FormikProvider>
    </>
  )
}

const SubscriptionDetailWrapper = () => {
  return (
    <>
      <DetailResponseProvider>
        <SubsscriptionDetail />
      </DetailResponseProvider>
    </>
  )
}

export {SubscriptionDetailWrapper}
