import clsx from 'clsx'
import {FC, FocusEventHandler, Key, ReactNode} from 'react'
import Select, {ActionMeta, GroupBase, OptionsOrGroups, components, Options} from 'react-select'
import Creatable from 'react-select/creatable'

type Props = {
  options?: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined
  onChange?: any
  placeholder?: ReactNode
  isMulti?: any
  onCreateOption?: (inputValue: string) => void | undefined
  value?: any
  isOptionDisabled?: (option: any, selectValue: Options<any>) => boolean
  isClearable?: boolean
  isDisabled?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement>
  isValid?: boolean
  errorMessage?: string
  defaultValue?: any
  className?: string | undefined
  type?: 'filled' | 'default'
  isLoading?: boolean
  selectKey?: string
  menuPortalTarget?: HTMLElement
}

const DropdownSelect: FC<Props> = ({
  options,
  onChange,
  placeholder,
  isMulti,
  onCreateOption,
  value,
  isOptionDisabled,
  isClearable,
  isDisabled,
  onBlur,
  isValid,
  errorMessage,
  defaultValue,
  className,
  type = 'default',
  isLoading,
  selectKey,
  menuPortalTarget,
}) => {
  if (type === 'filled') {
    return (
      <>
        <Select
          key={selectKey}
          styles={{
            control: (provided) => ({
              ...provided,
              border: 'none',
              borderRadius: 6,
            }),
            option: (provided) => ({
              ...provided,
              fontSize: 13,
            }),
            menuPortal: (provided) => ({
              ...provided,
              zIndex: 3,
            }),
          }}
          components={{
            IndicatorSeparator: null,
            Control: (props) => (
              <components.Control
                {...props}
                className={clsx(
                  props.className,
                  'form-select form-select-solid py-2 px-1 border-0'
                )}
              />
            ),
            // ClearIndicator: undefined,
            SelectContainer: (props) => (
              <components.SelectContainer
                {...props}
                className={clsx(props.className, 'border-0')}
              />
            ),
            Input: (props) => <components.Input {...props} className='py-0' />,
          }}
          className={className}
          isMulti={isMulti}
          defaultValue={defaultValue}
          options={options}
          onChange={onChange}
          onBlur={onBlur}
          isLoading={isLoading}
          placeholder={
            <span className='custom-dropdown-placeholder fw-semibold'>{placeholder}</span>
          }
          value={value}
          isClearable={isClearable}
          isDisabled={isDisabled}
          menuPortalTarget={menuPortalTarget}
        />
        {!isValid && errorMessage && <span className='text-danger mt-2'>{errorMessage}</span>}
      </>
    )
  }

  return (
    <>
      <Select
        key={selectKey ?? ''}
        components={{
          IndicatorSeparator: null,
          Control: (props) => (
            <components.Control
              {...props}
              className={clsx(props.className, 'custom-dropdown-control')}
            />
          ),
          Input: (props) => (
            <components.Input
              {...props}
              className={clsx(props.className, 'custom-dropdown-input')}
            />
          ),
        }}
        className={className}
        isMulti={isMulti}
        defaultValue={defaultValue}
        options={options}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={<span className='custom-dropdown-placeholder fw-semibold'>{placeholder}</span>}
        value={value}
        isLoading={isLoading}
        isClearable={isClearable}
        isDisabled={isDisabled}
        menuPortalTarget={menuPortalTarget}
      />
      {!isValid && errorMessage && <span className='text-danger mt-2'>{errorMessage}</span>}
    </>
  )
}

export {DropdownSelect}
