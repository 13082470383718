import {KTIcon, toAbsoluteUrl, validateImage250250_11} from '@/_metronic/helpers'
import {RESP} from '@/_metronic/helpers/network/network-consts'
import {Toggle} from '@/_metronic/partials/content/toggle/Toggle'
import {useSwal} from '@/app/core/SwalProvider'
import {mediaUpload} from '@/app/core/media/_requests'
import {useProductProviderForm} from '@/app/modules/product-modifier/core/ProductModifierFormProvider'
import {formatRupiah, removeDots} from '@/app/utils'
import clsx from 'clsx'
import {FieldArrayRenderProps, useFormikContext} from 'formik'
import {FC, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import {toast} from 'react-toastify'

type ValueRange = {
  minimum: number
  maximum: number
}

type Props = {
  isReadOnly?: boolean
  arrayHelpers: FieldArrayRenderProps
  index: number
  valueRange: ValueRange
  setIsMaxQtyValid: any
  isMaxQtyValid: any
}
const RowItem: FC<Props> = ({
  isReadOnly,
  arrayHelpers,
  index,
  valueRange,
  setIsMaxQtyValid,
  isMaxQtyValid,
}) => {
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<any>()
  const [errorMessage, setErrorMessage] = useState('')
  const {values, handleChange, handleBlur, touched, errors, setFieldValue} = useFormikContext<any>()

  const x = useMemo(() => {
    return values.details?.[index]
  }, [values])
  const i = useMemo(() => index, [])

  const {id} = useParams()
  const {swal} = useSwal()
  const {toDelete, setToDelete} = useProductProviderForm()

  const handleDeleteDetail = () => {
    if (id) {
      swal
        ?.fire({
          html: 'Apakah anda yakin ingin menghapus data ini?',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ya, hapus!',
          cancelButtonText: 'Tidak, batal',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn fw-bold btn-danger',
            cancelButton: 'btn fw-bold btn-active-light-primary',
          },
        })
        .then(async (value) => {
          if (value.isConfirmed) {
            const copyDeleted = toDelete.slice(0)
            copyDeleted.push(x.id)
            setToDelete(copyDeleted)
            deleteDetail()
          }
        })

      return
    }

    deleteDetail()
  }

  const deleteDetail = () => {
    if (values.details.length === 1) {
      arrayHelpers.push({
        name: '',
        images: '',
        description: '',
        min_quantity: 0,
        max_quantity: 0,
        price: 0,
        sold_out: false,
        active: false,
        action: 'create',
      })
    }
    arrayHelpers.replace(i, {
      guid: x.guid,
      name: x.name,
      images: x.images,
      description: x.description,
      min_quantity: x.min_quantity,
      max_quantity: x.max_quantity,
      price: x.price,
      sold_out: x.sold_out,
      active: x.active,
      action: 'delete',
    })
  }

  useEffect(() => {
    const listItem = isMaxQtyValid
    if (!listItem.find((item) => item.index === i)) {
      setIsMaxQtyValid((prevState) => [
        ...prevState,
        {
          index: i,
          valid: values.details[i].max_quantity <= valueRange.maximum,
        },
      ])
    } else {
      setIsMaxQtyValid(
        listItem.map((item, index) => {
          if (index === i) {
            return {
              index: i,
              valid: values.details[i].max_quantity <= valueRange.maximum,
            }
          } else {
            return item
          }
        })
      )
    }
  }, [values.details[i].max_quantity])

  const handleFileChange = (index: number) => async (event) => {
    const file = event.target.files?.[0]
    if (
      file &&
      (await validateImage250250_11({
        file,
        onError: (msg) => {
          // toast.error(msg)
          setErrorMessage(msg)
        },
      }))
    ) {
      handleUploadFile(file, index)
    }
  }

  const handleUploadFile = async (file: any, index: number) => {
    if (file) {
      try {
        setUploadLoading(true)
        const res = await mediaUpload(file)

        if (res?.message?.toLowerCase() === RESP.MSG_SUCCESS) {
          setSelectedFile({...selectedFile, preview: res?.data?.url})
          setFieldValue(`details.[${i}].images`, res?.data?.url)
          // setFileUrl(res?.data?.url)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setUploadLoading(false)
      }
    } else {
      setSelectedFile(null)
    }
  }

  return (
    <>
      <tr key={x.id}>
        <td>{i + 1}</td>
        <td className='min-w-250px'>
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".image-input-placeholder { background-image: url('assets/media/svg/files/blank-image.svg'); } [data-bs-theme=\"dark\"] .image-input-placeholder { background-image: url('assets/media/svg/files/blank-image-dark.svg'); }",
            }}
          />
          <div
            className='image-input image-input-empty image-input-outline image-input-placeholder input-image-wrapper w-100px h-100px position-relative'
            data-kt-image-input='true'
            style={{
              backgroundImage: `url(${values.details[i].images})`,
            }}
          >
            {/*begin::Preview existing avatar*/}
            {uploadLoading && (
              <div className='position-absolute w-100 h-100 d-flex flex-row justify-content-center align-items-center'>
                <div className='spinner-border'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            )}
            {!values.details[i].images && (
              <img
                src={toAbsoluteUrl(`/media/svg/files/blank-image.svg`)}
                alt={'No Photo'}
                className='image-input-wrapper w-100px h-100px'
              />
            )}
            {/*end::Preview existing avatar*/}
            {/*begin::Label*/}
            {!isReadOnly ? (
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                aria-label='Change avatar'
                data-bs-original-title='Change avatar'
                data-kt-initialized={1}
              >
                <i className='ki-duotone ki-pencil fs-7'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
                {/*begin::Inputs*/}
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={handleFileChange(i)}
                />
                <input type='hidden' name='avatar_remove' />
                {/*end::Inputs*/}
              </label>
            ) : (
              <></>
            )}
            {/*end::Label*/}
            {/*begin::Cancel*/}
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              aria-label='Cancel avatar'
              data-bs-original-title='Cancel avatar'
              data-kt-initialized={1}
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>{' '}
            </span>
            {/*end::Cancel*/}
            {/*begin::Remove*/}
            {!isReadOnly ? (
              <span
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                aria-label='Remove avatar'
                data-bs-original-title='Remove avatar'
                data-kt-initialized={1}
              >
                <i className='ki-duotone ki-cross fs-2'>
                  <span className='path1' />
                  <span className='path2' />
                </i>{' '}
              </span>
            ) : (
              <></>
            )}
            {/*end::Remove*/}
          </div>
          <div
            className={clsx('fs-7 mt-2', {
              'text-danger fw-bold': !!errorMessage,
              'text-muted': !errorMessage,
            })}
          >
            file type .jpg .png with min:250KB max:1MB and ration 1:1
          </div>
        </td>
        <td className='min-w-200px'>
          {isReadOnly ? (
            `${x.name}`
          ) : (
            <>
              <div className='d-flex'>
                <div className='input-group'>
                  <input
                    type='text'
                    className={clsx('form-control', {
                      'is-invalid': Boolean(errors?.details?.[i]?.name),
                    })}
                    aria-label='Name'
                    aria-describedby='name'
                    onBlur={handleBlur(`details.[${i}].name`)}
                    value={values.details[i].name}
                    onChange={handleChange(`details.[${i}].name`)}
                  />
                </div>
              </div>
              <span className='text-danger mt-1'>{errors?.details?.[i]?.name}</span>
            </>
          )}
        </td>
        <td className='min-w-300px'>
          {isReadOnly ? (
            `${x.desciption}`
          ) : (
            <>
              <div className='d-flex'>
                <div className='input-group'>
                  <input
                    type='text'
                    className={clsx('form-control', {
                      'is-invalid': Boolean(errors?.details?.[i]?.description),
                    })}
                    aria-label='Description'
                    aria-describedby='description'
                    onBlur={handleBlur(`details.[${i}].description`)}
                    value={values.details[i].description}
                    onChange={handleChange(`details.[${i}].description`)}
                  />
                </div>
              </div>
              <span className='text-danger mt-1'>{errors?.details?.[i]?.description}</span>
            </>
          )}
        </td>
        <td className='min-w-200px'>
          {isReadOnly ? (
            `${x.min_quantity}`
          ) : (
            <>
              <div className='d-flex'>
                <div className='input-group'>
                  <input
                    type='text'
                    className={clsx('form-control', {
                      'is-invalid': Boolean(errors?.details?.[i]?.min_quantity),
                    })}
                    aria-label='Minimum Quantity'
                    aria-describedby='min_quantity'
                    // onBlur={handleBlur(`details.[${i}].min_quantity`)}
                    value={formatRupiah(values.details[i].min_quantity) || ''}
                    onChange={(e) => {
                      try {
                        const numberQty = parseInt(removeDots(e.target.value?.toString()))
                        setFieldValue(
                          `details.[${i}].min_quantity`,
                          isNaN(numberQty) ? '' : numberQty
                        )
                      } catch (error) {
                        setFieldValue(`details.[${i}].min_quantity`, '')
                      }
                    }}
                  />
                </div>
              </div>
              <span className='text-danger mt-1'>{errors?.details?.[i]?.min_quantity}</span>
            </>
          )}
        </td>
        <td className='min-w-200px'>
          {isReadOnly ? (
            `${x.max_quantity}`
          ) : (
            <>
              <div className='d-flex'>
                <div className='input-group'>
                  <input
                    type='text'
                    name={`details.[${i}].max_quantity`}
                    className={clsx('form-control', {
                      'is-invalid':
                        Boolean(errors?.details?.[i]?.max_quantity) ||
                        values.details[i].max_quantity > valueRange.maximum,
                    })}
                    aria-label='Maximum Quantity'
                    aria-describedby='max_quantity'
                    onBlur={handleBlur(`details.[${i}].max_quantity`)}
                    value={formatRupiah(values.details[i].max_quantity) || ''}
                    onChange={(e) => {
                      try {
                        const numberQty = parseInt(removeDots(e.target.value?.toString()))
                        setFieldValue(
                          `details.[${i}].max_quantity`,
                          isNaN(numberQty) ? '' : numberQty
                        )
                      } catch (error) {
                        setFieldValue(`details.[${i}].max_quantity`, '')
                      }
                    }}
                  />
                </div>
              </div>
              <span className='text-danger mt-1'>{errors?.details?.[i]?.max_quantity}</span>
              {values.details[i].max_quantity > valueRange.maximum && (
                <span className='text-danger mt-1'>
                  Max Quantity tidak boleh lebih besar dari Max quantity di atas
                </span>
              )}
            </>
          )}
        </td>
        <td className='min-w-200px'>
          {isReadOnly ? (
            `${formatRupiah(x.price)}/${x?.raw_material?.unit_large_name}`
          ) : (
            <div className='d-flex'>
              <div className='input-group'>
                <input
                  type='text'
                  className={clsx('form-control', {
                    'is-invalid': Boolean(errors?.details?.[i]?.price),
                  })}
                  aria-label='Price'
                  aria-describedby='price'
                  onBlur={handleBlur(`details.[${i}].price`)}
                  value={formatRupiah(values.details[i].price) || ''}
                  onChange={(e) => {
                    try {
                      const numberPrice = parseInt(removeDots(e.target.value?.toString()))
                      setFieldValue(`details.[${i}].price`, isNaN(numberPrice) ? '' : numberPrice)
                    } catch (error) {
                      setFieldValue(`details.[${i}].price`, '')
                    }
                  }}
                />
                <span className='text-danger mt-1'>{errors?.details?.[i]?.price}</span>
              </div>
            </div>
          )}
        </td>
        <td className='min-w-200px'>
          {isReadOnly ? (
            `${x.sold_out}`
          ) : (
            <div className='d-flex'>
              <div className='input-group'>
                <Toggle
                  id={`sold_out_${i}`}
                  checked={values.details[i].sold_out}
                  onChange={handleChange(`details.[${i}].sold_out`)}
                />
              </div>
            </div>
          )}
        </td>
        <td className='min-w-200px'>
          {isReadOnly ? (
            `${x.active}`
          ) : (
            <div className='d-flex'>
              <div className='input-group'>
                <Toggle
                  id={`active_${i}`}
                  checked={values.details[i].active}
                  onChange={handleChange(`details.[${i}].active`)}
                />
              </div>
            </div>
          )}
        </td>
        {!isReadOnly && (
          <td className='w-125px'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-5'
              onClick={handleDeleteDetail}
            >
              <KTIcon iconName='trash' className='fs-3 text-danger' />
            </button>
          </td>
        )}
      </tr>
    </>
  )
}

export {RowItem}
