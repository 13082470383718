import {useSwal} from '@/app/core/SwalProvider'
import {formatRupiah, removeDots} from '@/app/utils'
import {KTIcon} from '@/_metronic/helpers'
import clsx from 'clsx'
import {FieldArrayRenderProps, useFormikContext} from 'formik'
import {uniqueId} from 'lodash'
import {FC, useEffect, useMemo} from 'react'
import {useParams} from 'react-router-dom'
import {DropdownRawMaterial} from './DropdownRawMaterial'
import {useProductProviderForm} from '@/app/modules/product-modifier/core/ProductModifierFormProvider'
import {Toggle} from '@/_metronic/partials/content/toggle/Toggle'
import {DropdownProductPrice} from '@/app/modules/subscription/subscription-detail/components/DropdownProductPrice'

type ValueRange = {
  minimum: number
  maximum: number
}

type Props = {
  isReadOnly?: boolean
  arrayHelpers: FieldArrayRenderProps
  index: number
}
const RowItem: FC<Props> = ({isReadOnly, arrayHelpers, index}) => {
  const {values, handleChange, handleBlur, touched, errors, setFieldValue} = useFormikContext<any>()

  const x = useMemo(() => {
    return values.details?.[index]
  }, [values])
  const i = useMemo(() => index, [])

  const {id} = useParams()
  const {swal} = useSwal()
  const {toDelete, setToDelete} = useProductProviderForm()

  const handleDeleteDetail = () => {
    if (id) {
      swal
        ?.fire({
          html: 'Apakah anda yakin ingin menghapus data ini?',
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: 'Ya, hapus!',
          cancelButtonText: 'Tidak, batal',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn fw-bold btn-danger',
            cancelButton: 'btn fw-bold btn-active-light-primary',
          },
        })
        .then(async (value) => {
          if (value.isConfirmed) {
            const copyDeleted = toDelete.slice(0)
            copyDeleted.push(x.id)
            setToDelete(copyDeleted)
            deleteDetail()
          }
        })

      return
    }

    deleteDetail()
  }

  const deleteDetail = () => {
    if (values.details.length === 1) {
      arrayHelpers.push({
        product_variant_sku: '',
        sell_price: 0,
        qty: 0,
        action: 'create',
      })
    }
    arrayHelpers.replace(i, {
      guid: x.guid,
      product_variant_sku: x.product_variant_sku,
      sell_price: x.sell_price,
      qty: x.qty,
      action: 'delete',
    })
  }

  return (
    <>
      <tr key={x.id}>
        <td>{i + 1}</td>
        <td className='min-w-150px'>
          {isReadOnly ? (
            `${x.product_variant_sku.label}`
          ) : (
            <>
              <div className='d-flex'>
                <div className='input-group'>
                  <DropdownProductPrice
                    currentKey={`product-price-${i + 1}`}
                    onValueChange={(newValue) => {
                      if (newValue) {
                        setFieldValue(`details.[${i}].product_variant_sku`, newValue)
                        setFieldValue(`details.[${i}].sell_price`, newValue?.sell_price)
                      }
                    }}
                    currentValue={values.details[i].product_variant_sku}
                    isDisabled={!values.outlet}
                  />
                </div>
              </div>
              <span className='text-danger mt-1'>{errors?.details?.[i]?.name}</span>
            </>
          )}
        </td>
        <td className='min-w-200px'>
          {isReadOnly ? (
            `${x.sell_price}`
          ) : (
            <>
              <div className='d-flex'>
                <div className='input-group'>
                  <input
                    type='text'
                    className={clsx('form-control bg-gray-300', {
                      'is-invalid': Boolean(errors?.details?.[i]?.sell_price),
                    })}
                    aria-label='Sell Price'
                    aria-describedby='sell_price'
                    onBlur={handleBlur(`details.[${i}].sell_price`)}
                    value={formatRupiah(values.details?.[i]?.sell_price) || ''}
                    onChange={(e) => {
                      try {
                        const numberQty = parseInt(removeDots(e.target.value?.toString()))
                        setFieldValue(
                          `details.[${i}].sell_price`,
                          isNaN(numberQty) ? '' : numberQty
                        )
                      } catch (error) {
                        setFieldValue(`details.[${i}].sell_price`, '')
                      }
                    }}
                    readOnly
                  />
                </div>
              </div>
              <span className='text-danger mt-1'>{errors?.details?.[i]?.sell_price}</span>
            </>
          )}
        </td>
        <td className='min-w-200px'>
          {isReadOnly ? (
            `${x.qty}`
          ) : (
            <>
              <div className='d-flex'>
                <div className='input-group'>
                  <input
                    type='text'
                    name={`details.[${i}].qty`}
                    className={clsx('form-control', {
                      'is-invalid': Boolean(errors?.details?.[i]?.qty),
                    })}
                    aria-label='Quantity'
                    aria-describedby='qty'
                    onBlur={handleBlur(`details.[${i}].qty`)}
                    value={formatRupiah(values.details[i].qty) || ''}
                    onChange={(e) => {
                      try {
                        const numberQty = parseInt(removeDots(e.target.value?.toString()))
                        setFieldValue(`details.[${i}].qty`, isNaN(numberQty) ? '' : numberQty)
                      } catch (error) {
                        setFieldValue(`details.[${i}].qty`, '')
                      }
                    }}
                  />
                </div>
              </div>
              <span className='text-danger mt-1'>{errors?.details?.[i]?.qty}</span>
            </>
          )}
        </td>
        {!isReadOnly && (
          <td className='w-125px'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-5'
              onClick={handleDeleteDetail}
            >
              <KTIcon iconName='trash' className='fs-3 text-danger' />
            </button>
          </td>
        )}
      </tr>
    </>
  )
}

export {RowItem}
